.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

@import url("https://fonts.googleapis.com/css?family=Inconsolata|Montserrat:300,400&display=swap");

$size: 225;
$placeholder: rgb(200, 200, 200);

* {
  font-family: "Inconsolata", monospace;
}

$theme-colors: (
  warningOrangeDark: rgba(215, 155, 0, 1),
  warningOrange: rgba(250, 220, 160, 1),
  lightGrey: rgb(245, 245, 245),
  grey: rgb(240, 240, 240),
  pink: rgb(218, 16, 96),
  listener: rgb(138, 95, 208),
  blue: rgb(70, 160, 206),
  borderGrey: rgb(215, 215, 215),
  borderLightGrey: rgb(225, 225, 225),
  jsArea: rgb(245, 248, 252),
  greyText: rgb(90, 90, 90),
  greyIcon: rgb(0, 0, 0),
  lightGreyText: rgb(125, 125, 125),
  htmlCssArea: rgb(252, 249, 245),
  htmlCssName: rgb(86, 162, 155),
  borderDarkGrey: rgb(175, 175, 175),
  debug: rgba(0, 0, 0, 0.1),
  seeThroughWhite: rgba(255, 255, 255, 0.7),
  fileLine: rgba(140, 154, 53, 0.5),
  jsLine: rgba(70, 160, 206, 0.5),
  listenerLine: rgba(218, 16, 96, 0.5),
  systemLine: rgba(170, 170, 170, 0.5)
    /*  background:rgb(215,215,215),
  jsArea:rgb(145, 155, 158),
  fnName:rgb(69,139,205),
  specialFnName:rgb(220,60,110),

  cssArea:rgb(145, 155, 158),
  htmlArea:rgb(145, 155, 158)*/
);

.css3-shadow {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
/*==================================================
 * Drop shadow effect with box-shadow
 * ===============================================*/
.css3-shadow:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
  bottom: 0px;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 50%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

html,
body {
  overflow: hidden;
  overscroll-behavior: none;
}

.errorMarker {
  position: absolute;

  width: 100% !important;
  border-radius: unset !important;
  left: 0 !important;
}

.referenceMarker {
  position: absolute;
}

.name {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border: none;
  outline: none;
  font-weight: bold;
  position: relative;
  width: 100%;
  font-size: 20px;
}

.btn {
  font-family: "Montserrat", sans-serif;
  /*  font-weight: 400;*/
}

.picker {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 12px;
  user-select: none;
  cursor: default;
}

.error-style-tooltip > .tooltip-inner {
  background: rgba(250, 220, 160, 1);
  color: rgb(0, 0, 0);
  cursor: text;
}

.picker-style-tooltip > .tooltip-inner {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 12px;
  cursor: default;
  text-align: left;
}

.pickerBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
  cursor: default;
}

.modal {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  cursor: default;
}

.modalBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  cursor: default;
}

.args {
  border: none;
  outline: none;
  position: relative;
  font-size: 16px;
  width: 100%;
}

.stamp {
  transition: border 0.3s ease-out;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder;
}

$tooltip-bg: rgb(0, 0, 0);
$tooltip-arrow-color: rgb(0, 0, 0);

@import "../node_modules/bootstrap/scss/bootstrap";
